import React from 'react';

const Footer = () => {
  return (
    <footer className='bg-black py-8 text-center text-white'>
      <div className='container mx-auto'>
      Copyright ©2022 All rights reserved | This template is made with  ❤  <a href="https://hackerbro.in/" target="_blank" rel="noopener noreferrer"> by Hacker Bro Technologies</a>
      </div>
    </footer>
  );
};

export default Footer;
